import { ref, watchEffect } from "vue";
import { fetchService } from "~/utils/schedulerServices.service";
import type { SchedulerService } from "~~/types/SchedulerService";

export function useSchedulerServices(serviceId?: number | null) {
  const service = ref<SchedulerService | null>(null);
  const pending = ref(true);
  const cancel = ref<(() => void) | undefined>(undefined);

  watchEffect(() => {
    if (isNullOrUndefined(serviceId)) {
      return;
    }

    service.value = null;
    pending.value = true;

    fetchService(serviceId)
      .then((serviceResponse) => {
        if (serviceResponse === null) {
          return;
        }

        cancel.value = serviceResponse.cancel;

        const services = serviceResponse.data.services;

        if (services.length < 1 || services.length > 1) {
          return;
        }

        service.value = services[0];
      })
      .finally(() => {
        pending.value = false;
      });
  });

  return {
    cancel,
    pending,
    service,
  };
}
