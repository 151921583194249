import { fetchWithCancel, type FetchWithCancelResponse } from "~/utils/fetchWithCancel";
import type { ServiceResponse } from "~~/types/api/ServiceResponse";

export function fetchService(serviceId: number): Promise<FetchWithCancelResponse<ServiceResponse> | null> {
  if (serviceId !== undefined && serviceId <= 0) {
    return Promise.resolve(null);
  }

  return fetchWithCancel<ServiceResponse>(`/api/services/${serviceId}`).catch(() => null);
}
